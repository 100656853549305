import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, map, mergeMap, take } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AngularFireFunctions } from '@angular/fire/functions';
import { HistoryAddedField } from '@noldortech/plex-utilities';

import { User, PropertyUser, CombUser } from '../users/user.model';
import { LinkUserAndPropertyRequest, NewUser, AddPropertyRequest, AddPropertyResponse } from './property.model';
import { AuditLogService } from '../audit-log/audit-log.service';
import { UsersService } from '../users/users.service';
import { RegOwner } from '../registered-owners/registered-owner.model';
import { RegisteredOwnersService } from '../registered-owners/registered-owners.service';
import { ServiceProvider } from '../service-providers/models/service-provider.model';
import { environment } from '../../../../../../../src/environments/environment';
import { config } from '../../../../../../../src/configs/configs';
import { Property, PropertyHistoryEntry, HistoryField, ModifiedHistoryField } from '@plex/property_models';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { EntitiesService } from '../entities/entities.service';
import { Account } from '@plex/fin/models/account';

declare const toastr: any;

const linkUserAndPropertyCF = 'plexff-property-user-link';

const getUsersListAndEnvironment = (usersList: PropertyUser[] = []) => {
	const { admin, client, product } = environment;
	const condensedUsersList = usersList.map(({ primaryAgent, active, uid }) => ({ primaryAgent, active, uid }));
	return { environment: { admin, client, product }, usersList: condensedUsersList };
};

type AddRegOwnerToPropertyRequest = {
	userId: string;
	entityId: string;
	propertyId: string;
	registeredOwnerId: string;
	environment: EnvDetails;
	currentUser: User;
	sendEmail: boolean;
};

@Injectable()
export class PropertiesService {
	entityId;
	entityPropertiesCollection: AngularFirestoreCollection<Property[]>;
	propertyDoc: AngularFirestoreDocument<Property>;
	usersCollection: AngularFirestoreCollection<User[]>;
	loggedInUser: string;
	config = config[environment.product];
	currentUser: User;

	constructor(
		private auditLogService: AuditLogService,
		public afs: AngularFirestore,
		public router: Router,
		public usersService: UsersService,
		public registeredOwnersService: RegisteredOwnersService,
		public fb: FirebaseApp,
		private storage: AngularFireStorage,
		private auth: AuthenticationService,
		private store: Store,
		private entitiesService: EntitiesService,
		private functions: AngularFireFunctions
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.loggedInUser = sessionStorage.getItem('userId');
		this.auth.user.subscribe(userDetails => {
			if (userDetails) {
				this.currentUser = userDetails;
			}
		});
	}

	fetchEntityPropertiesWithRegisteredOwners(active: boolean) {
		if (this.entityId) {
			return this.entitiesService.getPropertiesForEntity(active).pipe(
				distinctUntilChanged(),
				mergeMap(async (properties: Property[]) => {
					const props = properties.map(async property => {
						const prop = { ...property };

						let ownerSnap: DocumentSnapshot<RegOwner>;
						if (prop.regOwners && prop.regOwners.length > 0) {
							const ownerPath = `entities/${this.entityId}/registered_owners/${prop.regOwners[0]}`;
							const ownerReference = this.afs.doc(ownerPath).ref;
							ownerSnap = (await ownerReference.get()) as DocumentSnapshot<RegOwner>;
						}

						prop.regOwnerName = 'No Current Owner Set';
						if (ownerSnap) {
							const ownerData = ownerSnap.data();
							if (ownerData) {
								if (ownerData.name) {
									prop.regOwnerName = ownerData.name;
								}
							}
						}

						return prop;
					});

					return await Promise.all(props);
				})
			);
		}
	}

	async addProperty(property, emailDetails) {
		const response = await this.functions
			.httpsCallable<AddPropertyRequest, AddPropertyResponse>('plexff-property-core-add')({
				property,
				entityId: this.entityId,
				currentUser: this.currentUser,
				emailDetails,
			})
			.toPromise();
		if (response.new) {
			return response.new;
		}
		throw ['already_exists', response.existing];
	}

	addRegisteredOwnerToProperty(ownerData: RegOwner, propertyId: string) {
		return this.registeredOwnersService.addRegisteredOwner(ownerData, propertyId);
	}

	setPropertyActivity(active: boolean, property) {
		const entityRef = this.afs.collection('entities').doc(this.entityId).ref;
		const entityPropertyRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(property.uid);
		const propertyUsersRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(property.uid).collection('users').ref;

		//REMOVE PROPERTY FROM ENTITY
		const updateEntityProperty = entityPropertyRef.update({
			active,
		});

		// UPDATE ENTITY COUNT
		const updatePropertiesCount = entityRef.get().then((entityDetails: any) => {
			const entityData = entityDetails.data();
			let propertiesCount = 0;

			if (entityData.propertiesCount) {
				propertiesCount = entityData.propertiesCount;
				active ? propertiesCount++ : propertiesCount--;
			}

			return entityRef.update({
				propertiesCount: propertiesCount,
			});
		});

		// GET PROPERTY USERS
		const updatePropertyUsers = propertyUsersRef.get().then((users: any) => {
			return users.forEach(user => {
				const userData = user.data();

				return this.removeUserFromProperty(userData.uid, property.uid, userData.type);
			});
		});

		return Promise.all([updateEntityProperty, updatePropertiesCount, updatePropertyUsers]).then(() => {
			toastr.success(`Property ${active ? 'reactivated' : 'removed'} successfully!`);
			let logData = {
				name: property.property_number,
				description: `Property was ${active ? 'reactivated' : 'removed'}`,
				type: `${active ? 'add' : 'remove'}`,
				category: 'properties',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}
	fetchPropertyUsers(propertyUID: string) {
		this.usersCollection = this.afs.collection(`entities/${this.entityId}/properties/${propertyUID}/users`, ref => ref.where('active', '==', true));

		return this.usersCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data();
					return data;
				});
			})
		);
	}

	fetchAllPropertyUsers(propertyUID: string) {
		this.usersCollection = this.afs.collection(`entities/${this.entityId}/properties/${propertyUID}/users`);

		return this.usersCollection.snapshotChanges().pipe(
			map(propertyUserChanges => {
				return propertyUserChanges.map(async propertyUser => {
					const userId = propertyUser.payload.doc.id;
					const userDoc = await this.afs.collection('users').doc(userId).ref.get();
					const userData: User = userDoc.data();
					if (userData && typeof userData === 'object') {
						return {
							id: userId,
							...propertyUser.payload.doc.data(),
							firstname: userData.firstname,
							surname: userData.surname,
							cell: userData.cell,
						};
					} else {
						return { id: userId, ...propertyUser.payload.doc.data() };
					}
				});
			}),
			mergeMap(asyncUserPromises => Promise.all(asyncUserPromises))
		);
	}

	fetchPropertyDetails(uid: string) {
		this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${uid}`);
		return this.propertyDoc.valueChanges();
	}

	fetchRegisteredOwner(regOwnerId) {
		const ownerDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}`);
		return ownerDoc.valueChanges();
	}

	fetchPropertyRegisteredOwners(propertyId: string) {
		const registeredOwnersRef = this.afs.collection(`entities/${this.entityId}/properties/${propertyId}/registered_owners`, ref => ref.where('active', '==', true));
		return registeredOwnersRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data: any = a.payload.doc.data();
					data.uid = a.payload.doc.id;
					data.details = this.afs.doc(`entities/${this.entityId}/registered_owners/${data.uid}`).valueChanges();
					return data;
				});
			})
		);
	}

	fetchAllPropertyRegisteredOwners(propertyId: string) {
		const registeredOwnersRef = this.afs.collection(`entities/${this.entityId}/properties/${propertyId}/registered_owners`);
		return registeredOwnersRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data: any = a.payload.doc.data();
					data.uid = a.payload.doc.id;
					data.details = this.afs.doc(`entities/${this.entityId}/registered_owners/${data.uid}`).valueChanges();
					return data;
				});
			})
		);
	}

	fetchAllRegisteredOwners() {
		const ownersCollection = this.afs.collection(`entities/${this.entityId}/registered_owners`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));

		return ownersCollection.valueChanges({ idField: 'uid' });
	}

	fetchPropertyGallery(propertyUID) {
		const galleryCollection = this.afs.collection(`entities/${this.entityId}/properties/${propertyUID}/gallery`, ref =>
			ref.where('active', '==', true).orderBy('order', 'asc')
		);

		return galleryCollection.valueChanges({ idField: 'uid' });
	}

	deleteGalleryImage(entityID, propertyUID, imageID) {
		const deleteImage = this.afs.doc(`entities/${entityID}/properties/${propertyUID}/gallery/${imageID}`);
		return deleteImage.delete();
	}

	updateGalleryImageInfo(img: any) {
		const imageDoc = this.afs.doc(`entities/${this.entityId}/properties/${img.propertyUID}/gallery/${img.uid}`);
		return imageDoc.update({
			order: img.order,
		});
	}

	updatePropertyFeatureImage(img: any) {
		const propImage = this.afs.doc(`entities/${this.entityId}/properties/${img.propertyUID}`);
		return propImage.set(
			{
				featureImage: img.downloadFileThumbnail,
				featureImageId: img.uid,
			},
			{ merge: true }
		);
	}

	addExistingRegOwnerToProperty(registeredOwnerId, propertyId, regOwners, propertyNumber?: string) {
		const { client, admin, product } = environment;
		return this.functions
			.httpsCallable<AddRegOwnerToPropertyRequest>('plexff-property-regOwner-add')({
				registeredOwnerId,
				propertyId,
				entityId: this.entityId,
				userId: this.currentUser.id || this.currentUser.uid,
				environment: { client, admin, product },
				currentUser: this.currentUser,
				sendEmail: true,
			})
			.toPromise()
			.then(() => {
				this.propertyToOwnerHistory(registeredOwnerId, propertyNumber, 'added');
			});
	}

	removeRegisteredOwnerFromProperty(regOwnerId, propertyId, regOwners, property?: string) {
		// console.log(`Remove ${regOwnerId} from ${propertyId}`);
		const propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/registered_owners/${regOwnerId}`);
		const ownerDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}/properties/${propertyId}`);
		return propertyDoc.update({ active: false }).then(() => {
			return ownerDoc.update({ active: false }).then(() => {
				this.addAssociateOwnerHistoryToProperty(propertyId, regOwnerId, 'removed');
				this.propertyToOwnerHistory(regOwnerId, property, 'removed');
			});
		});
	}

	updateProperty(property: Property) {
		this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${property.uid}`);
		return this.propertyDoc
			.update(property)
			.then(() => {
				toastr.success('Your property has been updated!');
				let logData = {
					name: property.property_number,
					description: 'Property was updated',
					type: 'update',
					category: 'properties',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			})
			.catch(error => {
				toastr.error('Property could not be updated! Please try again.');
			});
	}

	updateRentalProperty(property: Property, rentalData) {
		console.log('property to update', rentalData);
		this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${property.uid}`);
		return this.propertyDoc
			.set(rentalData, { merge: true })
			.then(() => {
				toastr.success('Your property has been updated!');
				let logData = {
					name: property.property_number,
					description: 'Property was updated',
					type: 'update',
					category: 'properties',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			})
			.catch(error => {
				toastr.error('Property could not be updated! Please try again.');
			});
	}

	handleRentalModule(propertyNumber, propertyUID, rentalCheck) {
		// console.log("property to update", propertyUID);
		this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyUID}`);
		return this.propertyDoc
			.set(
				{
					activateRentalModule: rentalCheck,
				},
				{ merge: true }
			)
			.then(() => {
				if (rentalCheck) {
					toastr.success('Rental Module has been added!');
					let logData = {
						name: propertyNumber,
						description: 'Property rental module was added',
						type: 'update',
						category: 'properties',
						created: Date.now(),
					};
					this.auditLogService.addAudit(logData);
				} else {
					toastr.success('Rental Module has been removed!');
					let logData = {
						name: propertyNumber,
						description: 'Property rental module was removed',
						type: 'update',
						category: 'properties',
						created: Date.now(),
					};
					this.auditLogService.addAudit(logData);
				}
			})
			.catch(error => {
				toastr.error('Property could not be updated! Please try again.');
			});
	}

	updateRentalDetails(property: Property, rentalValues) {
		// console.log("property to update", property);
		const propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${property.uid}`);
		return propertyDoc
			.set(
				{
					extraDetails: rentalValues,
				},
				{ merge: true }
			)
			.then(() => {
				toastr.success('Your property has been updated!');
				let logData = {
					name: property.property_number,
					description: 'Property rental details was updated',
					type: 'update',
					category: 'properties',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			})
			.catch(error => {
				toastr.error('Property could not be updated! Please try again.');
			});
	}

	private async propertyToUserHistory(userId: string, propertyId: string, property: string, fieldAction: 'added' | 'removed'): Promise<void> {
		const changedField: HistoryAddedField = {
			field: `${fieldAction === 'added' ? 'Added' : 'Removed'} to User`,
			value: property,
			fieldAction,
		};
		this.usersService.addHistoryLogToUser([changedField], 'properties', userId, true);
	}

	private async propertyToOwnerHistory(ownerId: string, property: string, fieldAction: 'added' | 'removed') {
		const changedField: HistoryAddedField = {
			field: `${fieldAction === 'added' ? 'Added' : 'Removed'} ${fieldAction === 'added' ? 'to' : 'from'} Owner`,
			value: property,
			fieldAction,
		};
		await this.registeredOwnersService.addHistoryLogToRegisteredOwner([changedField], ownerId, 'property', true);
	}

	public async addAssociateOwnerHistoryToProperty(propertyId: string, ownerId: string, fieldAction: 'added' | 'removed'): Promise<void> {
		this.registeredOwnersService
			.fetchRegisteredOwner(ownerId)
			.pipe(take(1))
			.subscribe((owner: RegOwner) => {
				if (owner) {
					const changedField: HistoryAddedField = {
						field: `${fieldAction === 'added' ? 'Associated' : 'Disassociated'} Registered Owner`,
						value: owner.name,
						fieldAction,
					};
					this.addHistoryLogToProperty([changedField], propertyId, 'owner', true);
				}
			});
	}

	public async addHistoryLogToProperty(
		changed: Array<HistoryField | ModifiedHistoryField>,
		propertyId: string,
		historyType: 'user' | 'owner' | 'file' | 'note' | 'task' | 'property' | 'account',
		notifyUpdate: boolean,
		entityId = this.entityId
	): Promise<DocumentReference> {
		const propertyHistoryCollection = this.afs.collection(`entities/${entityId}/properties/${propertyId}/history`);
		const userDetails = this.auth.userDetails;

		const logData: PropertyHistoryEntry = {
			created: Date.now(),
			historyType,
			changed,
			propertyId,
			entityId,
			changedById: userDetails.uid,
			changedByName: `${userDetails.firstname} ${userDetails.surname}`,
			client: environment.client,
			admin: environment.admin,
			product: environment.product,
		};

		try {
			if (notifyUpdate) this.addPendingPropertyUpdates(logData);
			return await propertyHistoryCollection.add(logData);
		} catch (error) {
			throw new Error(error);
		}
	}

	addPendingPropertyUpdates(logData) {
		const pendingUpdatesCollection = this.afs.collection(`pending`);
		logData.request = 'notifyReceivePropertyUpdates';
		logData.entityId = this.entityId;
		logData.created = new Date();
		pendingUpdatesCollection.add(logData).catch(err => {
			console.log(err);
		});
	}

	public fetchPropertyHistory(propertyId: string): Observable<PropertyHistoryEntry[]> {
		const historyCollection = this.afs.collection(`entities/${this.entityId}/properties/${propertyId}/history`, ref => ref.orderBy('created', 'desc'));

		return historyCollection.valueChanges() as Observable<PropertyHistoryEntry[]>;
	}

	fetchPropertyHistoryUser(userId) {
		const userDoc = this.afs.doc(`users/${userId}`);
		return userDoc.valueChanges();
	}

	async addUserToProperty(userId: string, propertyId: string, propertyNumber: string, type: string, occupant: boolean, primaryAgent: boolean, usersList: PropertyUser[]) {
		try {
			await this.functions
				.httpsCallable<LinkUserAndPropertyRequest>(linkUserAndPropertyCF)({
					opType: 'add',
					loggedInUser: this.currentUser,
					data: {
						userId,
						propertyId,
						entityId: this.entityId,
						propertyNumber,
						type,
						occupant,
						primaryAgent,
						...getUsersListAndEnvironment(usersList),
					},
					sendEmail: true,
				})
				.toPromise();
			await this.propertyToUserHistory(userId, propertyId, propertyNumber, 'added');
			toastr.success('User successfully added');
		} catch (err) {
			console.error(err);
			toastr.error('Error adding user to property');
		}
	}

	async addNewUserToProperty(user: NewUser, property: Property, usersList: PropertyUser[]) {
		const userData = {
			firstname: user.newUserFirstname,
			surname: user.newUserSurname,
			email: user.newUserEmail,
		};

		const { uid: propertyId, property_number: propertyNumber } = property;
		const { newUserType: type, newUserOccupant: occupant, primaryAgent } = user;

		const data: LinkUserAndPropertyRequest['data'] = {
			addUserRequest: {
				userData,
				sendEmail: true,
			},
			propertyId,
			entityId: this.entityId,
			propertyNumber,
			type,
			occupant,
			primaryAgent,
			...getUsersListAndEnvironment(usersList),
		};
		try {
			await this.functions
				.httpsCallable<LinkUserAndPropertyRequest>(linkUserAndPropertyCF)({
					opType: 'add',
					loggedInUser: this.currentUser,
					data,
					sendEmail: true,
				})
				.toPromise();
			toastr.success('User successfully added');
		} catch (err) {
			console.error(err);
			toastr.error('Error adding user to property');
		}
	}

	async removeUserFromProperty(userID: string, propertyID: string, type: string, user?: User, property?: string) {
		const userRef = this.afs.collection('users').doc(userID);
		const propertyRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(propertyID);

		await userRef.collection('entities').doc(this.entityId).collection('properties').doc(propertyID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		await propertyRef.collection('users').doc(userID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		let typeObj = {};

		typeObj[type] = '';
		await propertyRef.set(typeObj, { merge: true });
		const userEntityRef = this.afs.collection('users').doc(userID).collection('entities').doc(this.entityId).ref;

		const entityDoc = await userEntityRef.get();
		let permission;
		const entity = entityDoc.data();
		if (type === 'primary' || type === 'alternate') {
			permission = 'owner';
		} else if (type === 'tenant') {
			permission = 'tenant';
		}

		const permToRemove = (entity.permissions as string[]).indexOf(permission, 0);
		if (permToRemove > -1) {
			(entity.permissions as string[]).splice(permToRemove, 1);
		}

		await userEntityRef.update(entity);

		if (type === 'tenant') {
			// FETCH ALTERNATE TENANT DETAILS IF ONE EXISTS ELSE SET TO BLANK FOR TENANT TYPE
			const tenantCollection = await propertyRef
				.collection('users', ref => ref.where('type', '==', 'tenant').where('active', '==', true))
				.snapshotChanges()
				.pipe(
					map(changes => {
						return changes.map(a => {
							const data = a.payload.doc.data() as User;
							data.uid = a.payload.doc.id;
							return data;
						});
					}),
					take(1)
				)
				.toPromise();

			if (tenantCollection.length === 0) {
				await propertyRef.set(
					{
						tenant: '',
					},
					{ merge: true }
				);
			} else {
				for (const tenant of tenantCollection) {
					await propertyRef.set(
						{
							tenant: tenant.firstname + ' ' + tenant.surname,
						},
						{ merge: true }
					);
				}
			}
		}

		let logData = {
			name: 'property',
			description: 'User removed from property',
			type: 'remove',
			category: 'properties',
			created: Date.now(),
		};

		if (property) {
			const changedPropertyField: HistoryAddedField = {
				field: `Associated Property Removed`,
				value: `${property}`,
				fieldAction: 'removed',
			};
			this.usersService.addHistoryLogToUser([changedPropertyField], 'properties', userID, true);
		}

		if (user) {
			const changedField: HistoryAddedField = {
				field: `Associated User Removed`,
				value: `${user.firstname} ${user.surname}, User type: ${type}`,
				fieldAction: 'removed',
			};
			await this.addHistoryLogToProperty([changedField], propertyID, 'user', true);
		}
		this.auditLogService.addAudit(logData);
	}

	async propertyFileHistoryLog(file, propertyId, action) {
		const changedField: HistoryAddedField = {
			field: `File ${action}`,
			value: `${file.name}, File type: ${file.filetype}`,
			fieldAction: action,
		};
		await this.addHistoryLogToProperty([changedField], propertyId, 'file', true);
	}

	fetchBondholders() {
		let entityID = this.entityId;
		if (environment.product === 'whitfields') {
			entityID = 'whitfields';
		}
		return this.afs
			.collection(`/entities/${entityID}/serviceProviders`, ref => ref.where('active', '==', true).where('type', '==', 'bondholder').orderBy('name', 'asc'))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as ServiceProvider;
						const sp = {
							name: data.name,
							uid: a.payload.doc.id,
						};
						return sp;
					});
				})
			);
	}

	addAccountToProperty(propertyId, accountData, entityId = this.entityId) {
		const propertyRef = this.afs.collection('entities').doc(entityId).collection('properties').doc(propertyId).collection('accounts').doc(accountData.id);

		return propertyRef
			.set(accountData, { merge: true })
			.then(() => {
				const changedField: HistoryAddedField = {
					field: 'Account',
					value: `New ${accountData.type} account association added. Name: ${accountData.name}`,
					fieldAction: 'added',
				};
				this.addHistoryLogToProperty([changedField], propertyId, 'account', true);
			})
			.catch(function (error) {
				console.log('Error associating account to property:', error);
			});
	}

	removeAccountFromProperty(propertyId, accountId, account?) {
		const propertyRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(propertyId).collection('accounts').doc(accountId);

		return propertyRef.set({ active: false }, { merge: true }).then(() => {
			if (account) {
				const changedField: HistoryAddedField = {
					field: 'Associated Account Removed',
					value: `${account.type} account association removed. Name: ${account.name}`,
					fieldAction: 'removed',
				};
				this.addHistoryLogToProperty([changedField], propertyId, 'account', true);
			}
		});
	}

	getAccountsForProperty(propertyId) {
		return this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/accounts`, ref => ref.orderBy('name', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllAccountsForProperty(propertyId): Observable<Account[]> {
		return this.afs.collection(`entities/${this.entityId}/properties/${propertyId}/accounts`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	// DOCUMENTS

	addPropertiesFolder(subfolderData, docUID, paths, propertyID) {
		// CREATE SUBFOLDER
		const propertiesSubfoldersCollection = this.afs.doc(`entities/${this.entityId}/documents/folders/list/${docUID}/subfolders/${propertyID}`);

		// ADD FOLDER USER REFERENCE
		const userRef = this.afs.collection('users').doc(this.loggedInUser);

		// ADD FOLDER DATA
		subfolderData.created = new Date();
		subfolderData.createdby = userRef.ref;
		subfolderData.path = paths;
		// console.log("Sub Paths: ", paths);
		subfolderData.active = true;
		propertiesSubfoldersCollection.set(subfolderData).then(() => {
			const foldersRef = this.afs.doc(`entities/${this.entityId}/documents/folders/list/${propertyID}`);

			foldersRef.set(subfolderData).then(() => {
				// console.log(propertyID);
				const pathRef = subfolderData.path;

				const location = `documents/${this.entityId}/${propertyID}`;
				const pathArray = {
					location: location,
					name: subfolderData.name,
				};
				pathRef.push(pathArray);

				propertiesSubfoldersCollection.update({
					ref: foldersRef.ref,
					path: pathRef,
					uid: propertyID,
					url: location,
					public: false,
				});
				foldersRef.update({
					ref: propertiesSubfoldersCollection.ref,
					path: pathRef,
					uid: propertyID,
					url: location,
					public: false,
				});
			});
		});
	}
	getPropertiesFolder() {
		return this.afs.doc<any>(`entities/${this.entityId}/documents/folders/list/properties`).valueChanges();
	}
	getPropertiesFilesLocation(propertyId) {
		return this.afs.doc<any>(`entities/${this.entityId}/documents/folders/list/${propertyId}`).valueChanges();
	}
	getPropertiesFiles(propertyId) {
		return this.afs
			.collection<any>(`entities/${this.entityId}/documents/folders/list/${propertyId}/docs`, ref => ref.orderBy('created', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	downloadPropertyFile(fileName): void {
		// console.log(fileName);
		let storageRef = this.fb.storage().ref();
		// console.log('storageRef', storageRef);
		storageRef
			.child(`entities/${this.entityId}/files/${fileName}`)
			.getDownloadURL()
			.then((url: any) => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = event => {
					/* Create a new Blob object using the response
					 *  data of the onload object.
					 */
					const blob = new Blob([xhr.response], { type: 'image/jpg' });
					const a: any = document.createElement('a');
					a.style = 'display: none';
					document.body.appendChild(a);
					const url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = fileName;
					a.click();
					window.URL.revokeObjectURL(url);
				};
				xhr.open('GET', url);
				xhr.send();
			})
			.catch(function (error) {
				// Handle any errors
				console.log(error);
			});
	}

	deletePropertyFile(propertyId, file) {
		const propertyFileDoc = this.afs.doc(`entities/${this.entityId}/documents/folders/list/${propertyId}/docs/${file.id}`);

		return propertyFileDoc
			.update({
				active: false,
			})
			.then(() => {
				this.propertyFileHistoryLog(file, propertyId, 'Removed');
				let logData = {
					name: 'Property file deleted',
					description: `${file.name} deleted from property (${propertyId})`,
					type: 'deleted',
					category: 'properties',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			});
	}

	getPropertyTasks(propertyId) {
		return this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/tasks`, ref => ref.orderBy('subject', 'asc'))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Property;
						return data;
					});
				})
			);
	}

	fetchFfPropertyTasks(propertyId) {
		return this.afs
			.collection(`entities/${this.entityId}/tasks`, ref =>
				ref.where('active', '==', true).where('entityId', '==', this.entityId).where('properties', 'array-contains', propertyId)
			)
			.valueChanges({ idField: 'id' });
	}

	fetchUserProperties(userUID: string) {
		return this.afs
			.collection('users')
			.doc(userUID)
			.collection('entities')
			.doc(this.entityId)
			.collection('properties', ref => ref.where('active', '==', true).orderBy('property_number', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	fetchAllUserProperties(userUID: string) {
		return this.afs
			.collection('users')
			.doc(userUID)
			.collection('entities')
			.doc(this.entityId)
			.collection('properties', ref => ref.orderBy('property_number', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	async addPropertyToUser(userId: string, propertyId: string, type: string, occupant: boolean, primaryAgent: boolean, usersList: PropertyUser[]) {
		try {
			await this.functions
				.httpsCallable<LinkUserAndPropertyRequest>(linkUserAndPropertyCF)({
					opType: 'add',
					loggedInUser: this.currentUser,
					data: {
						userId,
						propertyId,
						entityId: this.entityId,
						type,
						occupant,
						primaryAgent,
						...getUsersListAndEnvironment(usersList),
					},
					sendEmail: true,
				})
				.toPromise();
			toastr.success('Property successfully added to user');
		} catch (err) {
			console.error(err);
			toastr.error('Error adding property to user');
		}
	}

	async editUserPropertyAssociation(
		userUID: string,
		propertyUID: string,
		type: string,
		occupant: boolean,
		primaryAgent: boolean,
		usersList: PropertyUser[]
	): Promise<any | void> {
		const propertyUsersRef = this.afs.collection(`/entities/${this.entityId}/properties/${propertyUID}/users`, ref =>
			ref.where('type', '==', type).where('active', '==', true)
		);
		const propertyUserRef = this.afs.doc(`/entities/${this.entityId}/properties/${propertyUID}/users/${userUID}`).ref;
		const propertyAgentsRef = this.afs.collection(`/entities/${this.entityId}/properties/${propertyUID}/users`);

		if (type !== 'tenant' && type !== 'agent') {
			// CHECK IF TYPE CHANGED
			return propertyUserRef.get().then((user: any) => {
				const userData = user.data();

				if (userData.type === type) {
					// IF TYPE NOT CHANGED UPDATE OCCUPANT
					return this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent);
				} else {
					return propertyUsersRef
						.snapshotChanges()
						.pipe(take(1))
						.toPromise()
						.then(async (usersCollection: any) => {
							const promises: Promise<any>[] = [];
							if (usersCollection.length === 0) {
								// TYPE DOES NOT EXIST
								promises.push(this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent));
								this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyUID}`);
								promises.push(this.propertyDoc.update({ [type]: `${userData.firstname} ${userData.surname}` }));
							} else {
								if (this.config['multipleAlternates']) {
									promises.push(this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent));
									this.propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyUID}`);
									promises.push(this.propertyDoc.update({ [type]: `${userData.firstname} ${userData.surname}` }));
								} else {
									promises.push(Promise.reject(`A ${type} contact already exists on this property`));
								}
							}

							return await Promise.all(promises);
						})
						.catch(error => {
							console.log('Error during update - ', error);
							toastr.error(error);
						});
				}
			});
		} else {
			return new Promise((resolve, reject) => {
				if (primaryAgent === true) {
					const primaryAgents = usersList.filter(user => {
						if (user.primaryAgent === true && user.active === true) {
							return user;
						}
					});
					if (primaryAgents.length > 0) {
						const agentsArray = [];
						return primaryAgents.forEach((agent, index, array) => {
							return propertyAgentsRef
								.doc(agent.uid)
								.set(
									{
										primaryAgent: false,
									},
									{ merge: true }
								)
								.then(() => {
									agentsArray.push(agent);
									if (agentsArray.length === array.length) {
										return this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent)
											.then(() => {
												resolve('');
											})
											.catch(error => {
												reject();
											});
									}
								});
						});
					} else {
						return this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent)
							.then(() => {
								resolve('');
							})
							.catch(error => {
								reject();
							});
					}
				} else {
					return this.updatePropertyForUser(userUID, propertyUID, type, occupant, primaryAgent)
						.then(() => {
							resolve('');
						})
						.catch(error => {
							reject();
						});
				}
			});
		}
	}

	updatePropertyForUser(userUID: string, propertyUID: string, type: string, occupant: boolean, primaryAgent: boolean) {
		const userPropertyRef = this.afs.doc(`users/${userUID}/entities/${this.entityId}/properties/${propertyUID}`);
		const propertyUserRef = this.afs.doc(`/entities/${this.entityId}/properties/${propertyUID}/users/${userUID}`);

		const updateUserProperty = userPropertyRef.update({
			type: type,
			occupant: occupant,
			primaryAgent: primaryAgent,
		});

		const updatePropertyUser = propertyUserRef.update({
			type: type,
			occupant: occupant,
			primaryAgent: primaryAgent,
		});

		return Promise.all([updateUserProperty, updatePropertyUser]);
	}

	async removePropertyFromUser(userID: string, propertyID: string, type: string) {
		const userRef = this.afs.collection('users').doc(userID);
		const propertyRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(propertyID);

		await userRef.collection('entities').doc(this.entityId).collection('properties').doc(propertyID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		await propertyRef.collection('users').doc(userID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		let typeObj = {};

		typeObj[type] = '';
		await propertyRef.set(typeObj, { merge: true });
		//Set Permission Blank if user is unlinked from property

		const userEntityRef = this.afs.collection('users').doc(userID).collection('entities').doc(this.entityId).ref;
		const entityDoc = await userEntityRef.get();
		let permission;
		const entity = entityDoc.data();
		if (type === 'primary' || type === 'alternate') {
			permission = 'owner';
		} else if (type === 'tenant') {
			permission = 'tenant';
		}

		const permToRemove = (entity.permissions as string[]).indexOf(permission, 0);
		if (permToRemove > -1) {
			(entity.permissions as string[]).splice(permToRemove, 1);
		}

		await userEntityRef.update(entity);

		if (type === 'tenant') {
			// FETCH ALTERNATE TENANT DETAILS IF ONE EXISTS ELSE SET TO BLANK FOR TENANT TYPE
			const tenantCollection = await propertyRef
				.collection('users', ref => ref.where('type', '==', 'tenant').where('active', '==', true))
				.snapshotChanges()
				.pipe(
					map(changes => {
						return changes.map(a => {
							const data = a.payload.doc.data() as User;
							data.uid = a.payload.doc.id;
							return data;
						});
					}),
					take(1)
				)
				.toPromise();

			if (tenantCollection.length === 0) {
				await propertyRef.set(
					{
						tenant: '',
					},
					{ merge: true }
				);
			} else {
				for (const tenant of tenantCollection) {
					await propertyRef.set(
						{
							tenant: tenant.firstname + ' ' + tenant.surname,
						},
						{ merge: true }
					);
				}
			}
		}
	}

	removePending(pending) {
		if (pending.send_type === 'email' || pending.send_type === 'printed') {
			if (!pending.send_statement) {
				return this.storage.storage
					.ref(`reports/${pending.userId}.pdf`)
					.delete()
					.then(() => {
						return this.afs
							.doc(`entities/${this.entityId}/properties/${pending.propertyId}/pending/${pending.userId}`)
							.set({ active: false, downloadPDF: '' }, { merge: true });
					});
			} else {
				return this.afs
					.doc(`entities/${this.entityId}/properties/${pending.propertyId}/pending/${pending.userId}`)
					.set({ active: false, downloadPDF: '' }, { merge: true });
			}
		} else {
			if (!pending.send_statement) {
				return this.storage.storage
					.ref(`reports/${pending.userId}.csv`)
					.delete()
					.then(() => {
						return this.afs
							.doc(`entities/${this.entityId}/properties/${pending.propertyId}/pending/${pending.userId}`)
							.set({ active: false, downloadPDF: '' }, { merge: true });
					});
			} else {
				return this.afs
					.doc(`entities/${this.entityId}/properties/${pending.propertyId}/pending/${pending.userId}`)
					.set({ active: false, downloadPDF: '' }, { merge: true });
			}
		}
	}

	updateAssociatedPropertyDetails(propertyId, owner, changedFields, type) {
		const ownerId = owner.uid || owner.id;

		const fields: Array<ModifiedHistoryField | HistoryField> = changedFields.map((field: HistoryField): ModifiedHistoryField | HistoryField => {
			if (field.field === 'addType') {
				field.field = 'owner_type';
			}
			if (field.field === 'editUserType') {
				field.field = 'type';
			}
			if (field.field === 'editUserOccupant') {
				field.field = 'occupant';
			}
			if (owner[field.field]) {
				let permission: string = '';
				if (field.value !== 'alternate' && field.value !== 'primary') {
					permission = 'tenant';
				}

				if (field.value !== 'tenant') {
					permission = 'owner';
				}

				this.afs.doc(`users/${ownerId}/entities/${this.entityId}`).update({
					permissions: [permission],
				});
				return {
					field: field.field,
					from: owner[field.field],
					to: field.value,
					fieldAction: 'modified',
				} as ModifiedHistoryField;
			} else {
				return {
					field: field.field,
					value: field.value,
					fieldAction: 'added',
				} as HistoryField;
			}
		});
		this.addHistoryLogToProperty(fields, propertyId, type, true);
	}

	async updateUserCombAccess(userId: string, user: PropertyUser, combOrgId: string, combUnitNumber: string, propertyId: string, type: string) {
		const initials = user.firstname
			.split(' ')
			.map(name => name.charAt(0))
			.join('');
		let combPendingData: CombUser;
		if (type === 'add') {
			combPendingData = {
				ORGID: combOrgId,
				TYPE: type,
				FIRSTNAME: user.firstname.trim(),
				LASTNAME: user.surname.trim(),
				EMAIL: user.email,
				CELL: user?.cell || '',
				INITIALS: initials,
				UNITNUMBER: combUnitNumber.toString(),
			};
		} else if (type === 'delete') {
			combPendingData = {
				ORGID: combOrgId,
				TYPE: type,
				NAME: `${user.firstname.trim()} ${user.surname.trim()}`,
				UNITNUMBER: combUnitNumber.toString(),
			};
		}

		const combPendingRef = this.afs.collection('seleniumTestCasePending');
		const userRef = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/users/${userId}`);

		try {
			await combPendingRef.add(combPendingData);

			await userRef.update({
				combAccessControl: type === 'add',
			});
		} catch (error) {
			console.log(error);
		}
	}
}
