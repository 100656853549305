import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { AngularFireFunctions } from '@angular/fire/functions';
import dayjs from 'dayjs';
import { Store } from '@ngrx/store';

import { environment } from '../../../../../../environments/environment';
import { User } from '../../plex/users/user.model';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { WodApiService } from '../services/wod-api.service';
import { WhitfieldsCallLampyService } from '../whitfields-call-lampy.service';
import { Entity } from '@plex/entities/entities.model';
import { TypesenseService } from 'src/app/_shared/typesense/typesense.service';
import { AccountSummaryI } from './accounts/debtors-account-summary/account-summary-model';
import { ApiService } from 'src/app/_shared/services/api.service';
import { StartEditingCellParams } from 'ag-grid-community';
import { CurrencyPipe } from '@angular/common';

interface AdvertisingScheduleReport {
	created: Date | firebase.firestore.Timestamp;
	createdBy: string;
	createdByName: string;
	createdByEmail: string;
	status: 'queue' | 'busy' | 'error' | 'done';
	error?: string;
	file?: {
		bucketPath: string;
		name: string;
		url: string;
	};
	task?: {
		taskURL: string;
	};
	cloudTask?: {
		name: string;
		docPath: string;
	};
}

interface GenericReportReport {
	created: Date | firebase.firestore.Timestamp;
	createdBy: string;
	createdByName: string;
	createdByEmail: string;
	status: 'queue' | 'busy' | 'error' | 'done';
	reportType: string;
	error?: string;
	environment: EnvDetails;
	file?: {
		bucketPath: string;
		name: string;
		url: string;
	};
	task?: {
		taskURL: string;
	};
}

interface NewAccountsReport {
	entity: Entity;
	currentUser: User;
	sendEmail: boolean;
	environment: EnvDetails;
	reportType: string;
}

interface InterestBillingNewPastelReport {
	endYearMonth: string;
	reportType: string;
	currentUser: User;
	sendEmail: boolean;
	environment: EnvDetails;
	isFirefly: boolean;
}

interface DebtorsAndAdvancedBalancesReport {
	reportType: string;
	currentUser: User;
	environment: EnvDetails;
}

export interface WodAccount {
	account: string;
	accountLink?: string;
	description: string;
	type: string;
}

@Injectable({
	providedIn: 'root',
})
export class MReportsService {
	currentSchemeBalances = new BehaviorSubject(undefined);
	private env: string;

	constructor(
		private wodApiService: WodApiService,
		public afs: AngularFirestore,
		private auditLogService: AuditLogService,
		private whitfieldsCallLampyService: WhitfieldsCallLampyService,
		private functions: AngularFireFunctions,
		private typesenseService: TypesenseService,
		private store: Store,
		private apiService: ApiService,
		private currencyPipe: CurrencyPipe
	) {
		const isDev = environment.firebase.projectId.toLowerCase().includes('dev');
		const isStaging = environment.firebase.projectId.toLowerCase().includes('staging');
		this.env = isDev ? 'dev' : isStaging ? 'staging' : 'live';
	}

	public generateAdvertisingScheduleReport({ uid, firstname, surname, email }: User) {
		return this.afs.collection<AdvertisingScheduleReport>('entities/whitfields/advertisingScheduleReports').add({
			created: new Date(),
			status: 'queue',
			createdBy: uid,
			createdByName: `${firstname} ${surname}`,
			createdByEmail: email,
		});
	}

	public generateOldPastelReport(reportType: string, currentUser: User, reportRequirements?: any) {
		const { admin, client, product } = environment;
		const reportData = {
			reportName: reportType,
			reportType: `lampy-ff-${this.env}-${reportType}`,
			currentUser,
			environment: {
				admin,
				client,
				product,
			},
			...reportRequirements,
		};
		return this.functions.httpsCallable(`ff-reports-oldPastelReports-run`)(reportData).toPromise();
	}

	public async generateNewAccountsReport(entity: Entity, loggedInUser: User) {
		const { admin, client, product } = environment;
		return this.functions
			.httpsCallable<NewAccountsReport>('ff-reports-generateNewAccountsReport')({
				entity: entity,
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
				reportType: `lampy-ff-${this.env}-fireflyNewAccountsReport`,
			})
			.toPromise();
	}

	public async generateInterestBillingNewPastelReport(data, loggedInUser: User) {
		const { admin, client, product } = environment;

		return this.functions
			.httpsCallable<InterestBillingNewPastelReport>('ff-reports-newInterestBillingToDebtors-run')({
				...data,
				reportType: `lampy-ff-${this.env}-${data.reportType}`,
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
			})
			.toPromise();
	}

	public async generateClosedAccountsBalancesReport(data, loggedInUser: User) {
		const { admin, client, product } = environment;

		return this.functions
			.httpsCallable<FixMeAny>('ff-reports-closedAccountsBalances-run')({
				...data,
				reportType: `lampy-ff-${this.env}-${data.reportType}`,
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
			})
			.toPromise();
	}

	public async generateDebtorsAndAdvancedBalancesReport(loggedInUser: User) {
		const { admin, client, product } = environment;

		return this.functions
			.httpsCallable<DebtorsAndAdvancedBalancesReport>('ff-reports-debtorsAndAdvancedBalances-run')({
				reportType: `lampy-ff-${this.env}-oldPastelReport`,
				currentUser: loggedInUser,
				environment: { admin, client, product },
			})
			.toPromise();
	}

	public getLatestAdvertisingScheduleReport() {
		return this.afs
			.collection<AdvertisingScheduleReport>('entities/whitfields/advertisingScheduleReports', ref => ref.orderBy('created', 'desc').limit(1))
			.snapshotChanges()
			.pipe(
				map(actions => {
					return actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data, created: (data.created as firebase.firestore.Timestamp).toDate() };
					});
				})
			);
	}

	async getAccounts(prefix: string, useNewPastel?: boolean): Promise<WodAccount[]> {
		const { accounts } = await this.whitfieldsCallLampyService.getLampyAccounts(prefix, useNewPastel);
		return accounts
			.map(({ account_description, account_type, account_type_code, type, account }) => {
				return { account, description: account_description, type: type || account_type, accountTypeCode: account_type_code };
			})
			.map(account => {
				if (account.type.includes('Income')) {
					account.type = 'Income';
				} else if (account.type.includes('Expense')) {
					account.type = 'Expense';
				} else {
					account.type = 'Balance Sheet';
				}
				return account;
			});
	}

	getAgeAnalysis(url: string) {
		return this.wodApiService.getData(url);
	}

	getTermsAndConditions(prefix) {
		return this.wodApiService.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/reports/termsandconditions.pl?prefix=${prefix}`);
	}

	async fetchSchemePaidPayments(entityPrefix: string, startDate: string | Date, endDate: string, statusValues: string[]) {
		const convertedStartDate = dayjs(startDate).toDate();
		const convertedEndDate = dayjs(endDate).toDate();
		const paymentsRef = await this.afs
			.collection(`prsOldPastel/payments/list`)
			.ref.where('complexPrefix', '==', entityPrefix)
			.where('status', 'in', statusValues)
			.where('releaseDate', '>=', firebase.firestore.Timestamp.fromDate(convertedStartDate))
			.where('releaseDate', '<=', firebase.firestore.Timestamp.fromDate(convertedEndDate))
			.get();

		if (paymentsRef.docs.length > 0) {
			const invoiceTotals = paymentsRef.docs.map((doc: any) => doc.data().invoiceTotal);
			return invoiceTotals.reduce((acc, curr) => acc + curr);
		} else {
			return 0;
		}
	}

	async getFireflyCurrentBalances(prefix, accounts, options) {
		const balances = await this.whitfieldsCallLampyService.getLampySchemeBalances(prefix, accounts, options.endDate, options.buffer, options.amountBuffer, options.overdraft);
		this.currentSchemeBalances.next({ accounts: balances.accountBalances, available: balances.availableBalance });
		return { accounts: balances.accountBalances, available: balances.availableBalance };
		// return Promise.resolve();
	}

	formatAmount(amount) {
		if (amount) {
			if (typeof amount === 'string') {
				return this.currencyPipe.transform(parseFloat(amount.replace(/ /g, '')), 'R ').replace(/,/g, ' ');
			} else {
				return this.currencyPipe.transform(parseFloat(amount), 'R ').replace(/,/g, ' ');
			}
		} else {
			return 'R 0.00';
		}
	}

	// Calculate available payment balances
	async calculateSchemeAvailableBalances(accounts, entity) {
		let options: any = {};
		const prefix = entity.isFirefly ? entity.prefix : entity.whitfieldsPrefix;
		options.endDate = dayjs().format('YYYY-MM-DD');
		options.buffer = entity['bufferOverride'] ? entity['bufferOverride'].toLowerCase() : 'no';
		options.amountBuffer = entity['buffer'] ? entity['buffer'] : '0';
		if (typeof options.amountBuffer === 'string') {
			options.amountBuffer = parseFloat(options.amountBuffer.replace(/ /g, '').replace('R', ''));
		}
		options.overdraft = entity['overdraft'] ? entity['overdraft'] : '';
		if (typeof options.overdraft === 'string') {
			options.overdraft = parseFloat(options.overdraft.replace(/ /g, '').replace('R', ''));
		}

		let result = await this.getFireflyCurrentBalances(prefix, accounts, options);
		if (result) {
			const currentDay = moment();
			//currentDay in string format
			const currentDayStr = currentDay.format('YYYY-MM-DD');

			const endOfDay = moment().endOf('day');
			const endDay = endOfDay.format('YYYY-MM-DD HH:mm');
			let preDay: string;
			const isMonday = currentDay.day() === 1;
			const is1159PM = currentDay.hour() === 23 && currentDay.minute() === 59;
			const lastDayNextMonth = moment().endOf('month').add(1, 'days').format('YYYY-MM-DD');

			if (isMonday && is1159PM) {
				preDay = currentDay.subtract(4, 'days').format('YYYY-MM-DD');
			} else {
				preDay = currentDay.subtract(2, 'days').format('YYYY-MM-DD');
			}

			const paidTotals = await this.fetchSchemePaidPayments(prefix, preDay, endDay, ['paid']);
			const scheduledTotals = await this.fetchSchemePaidPayments(prefix, currentDayStr, lastDayNextMonth, ['pending', 'approved', 'new']);
			result.available.total = this.formatAmount(+result.available.total - +paidTotals - +scheduledTotals);

			return result;
		} else {
			return Promise.reject('Error handling balances.');
		}
	}

	async getFireflyTrialBalance(entityId, prefix, selectedMonth, finYearEnd, ownBankAccount) {
		const months = {
			january: 1,
			february: 2,
			march: 3,
			april: 4,
			may: 5,
			june: 6,
			july: 7,
			august: 8,
			september: 9,
			october: 10,
			november: 11,
			december: 12,
		};
		const splitSelectedMonthYear = selectedMonth.split(' ');
		if (finYearEnd.toLowerCase()) {
			const accountsList = (
				await this.afs.collection(`/entities/${entityId}/fin/debtors/list`).ref.where('active', '==', true).where('isFirefly', '==', true).get()
			).docs.map((accountDoc: FixMeAny) => ({ ...accountDoc.data(), id: accountDoc.id }));
			const result = await this.whitfieldsCallLampyService.getLampyTrialBalance(
				prefix,
				splitSelectedMonthYear[0],
				splitSelectedMonthYear[1],
				months[finYearEnd.toLowerCase()] + 1,
				ownBankAccount,
				accountsList
			);
			return result;
		} else {
			return Promise.reject({ error: 'No Financial year end' });
		}
	}

	async getOldPastelTrialBalance(whitfieldsPrefix, selectedMonth, finYearEnd) {
		const months = {
			january: 1,
			february: 2,
			march: 3,
			april: 4,
			may: 5,
			june: 6,
			july: 7,
			august: 8,
			september: 9,
			october: 10,
			november: 11,
			december: 12,
		};
		const splitSelectedMonthYear = selectedMonth.split(' ');
		if (finYearEnd.toLowerCase()) {
			const result = await this.whitfieldsCallLampyService.getLampyOldPastelTrialBalance(
				whitfieldsPrefix,
				splitSelectedMonthYear[0],
				splitSelectedMonthYear[1],
				months[finYearEnd.toLowerCase()] + 1
			);
			return result;
		} else {
			return Promise.reject({ error: 'No Financial year end' });
		}
	}

	fetchStatementsProgress() {
		return this.afs.collection(`entities/whitfields/statements/status/entities`).valueChanges({ idField: 'id' });
	}
	getCurrentBalances(prefix, accounts, options) {
		// environment.admin = 'amiti-plex-live'; // NOTE: For debugging
		const getAccountBalances = () =>
			new Promise((res, rej) => {
				const accountList = [];
				accounts.forEach(account => {
					this.wodApiService
						.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/accounts/balances.pl?prefix=${prefix}&account=${account}`)
						.subscribe((data: any) => {
							accountList.push(data);
							if (accountList.length === accounts.length) {
								res(accountList);
							}
						});
				});
			});

		const getAvailableBalance = amount8400 =>
			new Promise((res, rej) => {
				if (amount8400) {
					this.wodApiService
						.getData(
							`https://plexapi.whitfields.co.za/complex/availablebalance.php?prefix=${prefix}&amount8400=${amount8400}&buffer=${
								options.bufferOverride
							}&amountBuffer=${options.buffer || '0'}&overdraft=${options.overdraft}`
						)
						.subscribe(data => {
							res(data);
						});
				} else {
					//{"paid":"2000","nextMonthDate":"2020-03-31","to pay":"194.85","to pay break down":[194.85],"overdraft":0,"bufferAmount":"0","total":"-12194.85"}
					const data = {
						paid: '0.00',
						nextMonthDate: '2020-03-31',
						'to pay': '0.00',
						'to pay break down': [194.85],
						overdraft: options.overdraft,
						bufferAmount: '0.00',
						total: '0.00',
					};
					res(data);
				}
			});

		const final = async () => {
			if (prefix) {
				if (!environment.admin.includes('dev')) {
					const accountBalances: any = await getAccountBalances();
					const get8400 = await accountBalances.filter(account => account.account === '8400')[0];
					let availablebalance: any = 0;
					if (get8400) {
						availablebalance = await getAvailableBalance(get8400['total'] ? get8400['total'] : null);
					}
					let data = {
						accounts: accountBalances,
						available: availablebalance,
					};
					return data;
				} else {
					return {
						accounts: [
							{
								endDate: '26/8/2021',
								account: '8403',
								total: '-10480.98',
							},
							{
								endDate: '26/8/2021',
								account: '8405',
								total: '0.60',
							},
							{
								endDate: '26/8/2021',
								account: '8407',
								total: '141128.68',
							},
							{
								endDate: '26/8/2021',
								account: '8410',
								total: '141128.68',
							},
						],
						available: {
							paid: '0',
							nextMonthDate: '2021-08-31',
							'to pay': '0',
							'to pay break down': [],
							overdraft: options.overdraft,
							bufferAmount: '0',
							total: '-20480.98',
						},
					};
				}
			}
			return null;
		};
		return final();
	}

	private genRefNo(taskType: string, prefix: string): string {
		const scheme = prefix !== 'WPM' ? prefix : 'FIREFLY';
		const splitType = taskType.split('');
		const type = `${splitType[0]}${splitType[1]}${splitType[2]}`.toUpperCase();

		return `${scheme}-${type}-${Math.floor(new Date().valueOf() * Math.random())
			.toString()
			.substring(2, 10)}`;
	}

	saveTask(user, type, title, prefix?, data?) {
		const taskId = this.afs.createId();
		const month = moment().format('MMMM YYYY');
		let task: any = {
			prefix: prefix !== '' ? prefix : '',
			request: 'add' + type,
			subject: title.subject,
			description: title.description !== '' ? title.description : 'Here is the ' + title.subject + ' csv file for ' + month,
			id: taskId,
			entityId: 'whitfields',
			active: true,
			refNo: this.genRefNo('General', 'WPM'),
			privacy: 'Restricted',
			managerId: user.uid,
			creatorId: user.uid,
			creatorName: `${user.firstname} ${user.surname}`,
			status: 'Submitted',
			tags: [],
			created: new Date(),
			updated: new Date(),
			taskType: 'General',
			taskTeam: [user.uid],
			watchers: [],
			properties: [],
			accounts: [],
			files: [],
			category: '',
			priority: 'High',
			OnHold: false,
			email: user.email,
		};
		if (type === 'LevyAdvanceBalance' || type === 'AdvanceReportMonthEnd') {
			task.day = data.day ? data.day : moment().format('DD');
			task.month = data.month;
			task.year = data.year;
		} else if (title.month && title.year) {
			task.month = title.month;
			task.year = title.year;
		} else {
			task.day = moment().format('DD');
			task.month = moment().format('MMMM');
			task.year = moment().format('YYYY');
		}
		task.createdBy = task.creatorId;
		task.createdByName = task.creatorName ? task.creatorName : '';
		let logData = {
			name: `${type} report`,
			description: `${user.firstname} ${user.surname} requested ${type} report.`,
			type: 'added',
			category: 'reports',
			created: Date.now(),
			reportType: type,
		};
		return this.afs
			.doc(`pending/${task.id}`)
			.set(task)
			.then(() => {
				return this.auditLogService.addAudit(logData, 'whitfields');
			});
	}

	saveCSV() {}

	async handleDebtorsAccountSummary(entityId, selectedMonthYear): Promise<AccountSummaryI> {
		let dateSplit = selectedMonthYear.split(' ');
		let startDate = `${dateSplit[1]}-${+dateSplit[0] - 1}-22`;
		let endDate = `${dateSplit[1]}-${dateSplit[0]}-21`;
		// let formatDate = moment(startDate).format('YYYY-MM-DD');
		// console.log(`CJ ~ formatDate`, formatDate);

		// return Promise.resolve([]);
		const accounts = [];
		let totals = { accountNumber: 'Totals', registeredOwner: '', opening: '', charges: '', receipts: '', closing: '' };
		const getAccounts = await this.afs.collection(`entities/${entityId}/fin/debtors/list`).ref.where('isFirefly', '==', true).orderBy('name', 'asc').get();
		for await (let accountDoc of getAccounts.docs) {
			let accountData: any = accountDoc.data();
			let account = { accountNumber: accountData.name, registeredOwner: '' };

			const accountRegisteredOwners = await this.afs.collection(`entities/${entityId}/fin/debtors/list/${accountDoc.id}/registered_owners`).ref.limit(1).get();
			accountRegisteredOwners.forEach((ownerDoc: any) => {
				account['registeredOwner'] = ownerDoc.data().name || '';
			});

			const getAccountLampy = await this.whitfieldsCallLampyService.getLampyFireflySchemeAccountSummary(account.accountNumber, startDate, endDate);
			account['opening'] = +getAccountLampy.balance.opening;
			account['charges'] = +getAccountLampy.balance.debit;
			account['receipts'] = +getAccountLampy.balance.credit;
			account['closing'] = +getAccountLampy.balance.balance;
			if (account['receipts'] > 0 || account['charges'] > 0) {
				if (account['closing'] > 0) {
					account['opening'] = account['opening'].toFixed(2);
					account['charges'] = account['charges'].toFixed(2);
					account['receipts'] = account['receipts'].toFixed(2);
					account['closing'] = account['closing'].toFixed(2);
					accounts.push(account);
				}
			}
		}
		totals.opening = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.opening;
			}, 0)
			.toFixed(2);
		totals.charges = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.charges;
			}, 0)
			.toFixed(2);
		totals.receipts = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.receipts;
			}, 0)
			.toFixed(2);
		totals.closing = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.closing;
			}, 0)
			.toFixed(2);
		let returnData: AccountSummaryI = { accounts: accounts, totals: totals, date: '' };
		return returnData;
	}

	async getStatementBatches(per_page: number, page: number, q: string = '*', query_by: string) {
		const { hits, found } = await this.typesenseService.searchCollection(`${environment.firebase.projectId}-statementBatch`, {
			q,
			query_by,
			page,
			per_page,
		});

		return { batches: hits.map(({ document }) => document), found };
	}

	async getStatementBatchLogs(entityId, batchId) {
		return await this.afs
			.collection(`/entities/whitfields/logs/statements/entities/${entityId}/batches/${batchId}/logs`)
			.ref.orderBy('created', 'desc')
			.get()
			.then((logs: any) => {
				return logs.docs.map((log: any) => {
					const logData = log.data();
					logData['id'] = log.id;
					return logData;
				});
				//return logs.docs;
			});
	}

	async getStatementBatch(entityId, batchId) {
		return await this.afs
			.doc(`/entities/whitfields/logs/statements/entities/${entityId}/batches/${batchId}`)
			.ref.get()
			.then((batch: any) => {
				return batch.data();
			});
	}

	sendStatementsLampy(data) {
		return this.afs.doc(`entities/whitfields/statements/status/entities/${data.entityId}`).set(data);
	}

	getStatementsLampySending() {
		return this.afs.collection(`entities/whitfields/statements/status/entities`).valueChanges();
	}

	public async generateClosedAccountsBalancesReportOld({ uid, firstname, surname, email }: User) {
		// Add back pendingReport functionality once CF upgrade is complete
		const { admin, client, product } = environment;
		const cloudWorkerData = {
			created: new Date(),
			createdBy: uid,
			createdByName: `${firstname} ${surname}`,
			createdByEmail: email,
		};
		const reportData = {
			...cloudWorkerData,
			status: 'busy',
			environment: { admin, client, product },
			reportType: `fireflyClosedAccountsBalancesReport`,
			reason: '',
		};

		try {
			// const reportRefId = await this.pendingReport(reportData);
			// cloudWorkerData.reportRefId = reportRefId;
			const rawResponse = await this.apiService.submitCWRequest('POST', 'firefly/generate-closed-accounts-report', cloudWorkerData);

			if (!rawResponse.ok) {
				const text = await rawResponse.text();
				// await this.pendingReport(reportRefId, 'error');
				throw new Error(text);
			}
			//return reportRefId;
		} catch (err) {
			console.log('🚀 ~ Generating Closed Accounts balances Report  ~ err:', err);
			throw new Error(err);
		}
	}

	public async generateAdvanceJournalReport({ uid, firstname, surname, email }: User) {
		// Add back pendingReport functionality once CF upgrade
		const { admin, client, product } = environment;
		const cloudWorkerData = {
			created: new Date(),
			createdBy: uid,
			createdByName: `${firstname} ${surname}`,
			createdByEmail: email,
			reportRefId: '',
		};
		const reportData = {
			...cloudWorkerData,
			status: 'busy',
			environment: { admin, client, product },
			reportType: `fireflyAdvanceJournalReport`,
			reason: '',
		};

		try {
			//const reportRefId = await this.pendingReport(reportData, null, null);
			// cloudWorkerData.reportRefId = reportRefId;
			const rawResponse = await this.apiService.submitCWRequest('POST', 'reports/create-advance-journal-report', cloudWorkerData);

			if (!rawResponse.ok) {
				const text = await rawResponse.text();
				//await this.pendingReport(null, reportRefId, 'error');
				throw new Error(text);
			}
			// return reportRefId;
		} catch (err) {
			console.log('🚀 ~ Generating Advanced Journals Report  ~ err:', err);
			throw new Error(err);
		}
	}

	public async pendingReport(reportData?: FixMeAny, reportId?: string, status?: string, reportCollection?: string) {
		try {
			if (reportId) {
				// update status to error
				await this.afs.doc(`pendingReports/${reportId}`).update({ status: status });
				return reportId;
			}
			if (reportData) {
				const reportRef = await this.afs.collection<GenericReportReport>(`pendingReports/${reportCollection}`).add({
					...reportData,
				});
				return reportRef.id;
			}
		} catch (err) {
			throw new Error(err);
		}
	}

	public async runStatementSend(data) {
		try {
			const rawResponse = await this.apiService.submitCWRequest('POST', 'firefly/send-statements-handler', data);

			if (!rawResponse.ok) {
				const text = await rawResponse.text();
				throw new Error(text);
			}
		} catch (err) {
			console.log('🚀 ~ StatementsSend ~ err:', err);
			throw new Error(err);
		}
	}

	public async updatePendingReportStatus(reportRef: string, status: string) {
		return this.afs.doc(reportRef).update({ status });
	}

	public async removeStatementFailedEntity(entityId) {
		return this.afs.doc(`entities/whitfields/statements/status/entities/${entityId}`).delete();
	}

	public async generatePaywayJournalReport(loggedInUser: User) {
		const { admin, client, product } = environment;

		try {
			const rawResponse = await this.apiService.submitCWRequest('POST', 'reports/create-payway-journal-report', {
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
			});

			if (!rawResponse.ok) {
				const text = await rawResponse.text();
				throw new Error(text);
			}
		} catch (err) {
			console.log('🚀 ~ payway journal ~ err:', err);
			throw new Error(err);
		}
	}

	async getWhitfieldsStaff() {
		return (await this.afs.collection(`entities/whitfields/users`).ref.where('active', '==', true).get()).docs.map((item: any) => ({ id: item.id, ...item.data() }));
	}

	async generateUserPaymentsMadeReport(loggedInUser, formData) {
		const { admin, client, product } = environment;

		try {
			const rawResponse = await this.apiService.submitCWRequest('POST', 'firefly/create-user-payments-made-report', {
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
				formData,
			});

			if (!rawResponse.ok) {
				const text = await rawResponse.text();
				throw new Error(text);
			}
		} catch (err) {
			console.log('🚀 ~ payway journal ~ err:', err);
			throw new Error(err);
		}
	}
}
